import { render, staticRenderFns } from "./GlobalSuppressionImport.vue?vue&type=template&id=38a88038&"
import script from "./GlobalSuppressionImport.vue?vue&type=script&lang=js&"
export * from "./GlobalSuppressionImport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VApp,VDivider,VStepper,VStepperContent,VStepperHeader,VStepperItems,VStepperStep})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('38a88038')) {
      api.createRecord('38a88038', component.options)
    } else {
      api.reload('38a88038', component.options)
    }
    module.hot.accept("./GlobalSuppressionImport.vue?vue&type=template&id=38a88038&", function () {
      api.rerender('38a88038', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/suppression/GlobalSuppressionImport.vue"
export default component.exports