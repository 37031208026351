<template>
  <v-app>
    <v-stepper v-model="stepCount">
      <v-stepper-header>
        <v-stepper-step
          :complete="stepCount > getStepVal('globalSuppressionFileUpload')"
          :step="getStepVal('globalSuppressionFileUpload')"
        >
          Upload Global Suppression
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="stepCount > getStepVal('importGlobalSuppression')"
          :step="getStepVal('importGlobalSuppression')"
        >
          Import Global Suppression
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="stepCount > getStepVal('globalSuppressionSummary')"
          :step="getStepVal('globalSuppressionSummary')"
        >
          Global Suppression Import Summary
        </v-stepper-step>
        <v-divider />
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content
          class="pa-3"
          :step="getStepVal('globalSuppressionFileUpload')"
        >
          <GlobalSuppressionFileUpload
            v-if="getStepVal('globalSuppressionFileUpload') == stepCount"
            ref="globalSuppressionFileUpload"
            :key="getStepKey('globalSuppressionFileUpload')"
            :step-val="getStepVal('globalSuppressionFileUpload')"
            :suppression-code="$route.query.sc"
            :step-count="stepCount"
            @row0="getRow"
            @fileArray="getFileArray"
            @fileData="getFileData"
            @cancelStep="cancelStep"
            @advanceFromStep="advanceFromStep"
          />
        </v-stepper-content>
        <v-stepper-content
          class="pa-3"
          :step="getStepVal('importGlobalSuppression')"
        >
          <ImportGlobalSuppression
            v-if="getStepVal('importGlobalSuppression') == stepCount"
            ref="importGlobalSuppression"
            :key="getStepKey('importGlobalSuppression')"
            :step-val="getStepVal('importGlobalSuppression')"
            :row0="row0"
            :file-array="fileArray"
            :file-data="fileData"
            @cancelStep="cancelStep"
            @advanceFromStep="advanceFromStep"
          />
        </v-stepper-content>
        <v-stepper-content
          class="pa-3"
          :step="getStepVal('globalSuppressionSummary')"
        >
          <GlobalSuppressionSummary
            v-if="getStepVal('globalSuppressionSummary') == stepCount"
            ref="globalSuppressionSummary"
            :key="getStepKey('globalSuppressionSummary')"
            :step-val="getStepVal('globalSuppressionSummary')"
            @cancelStep="cancelStep"
            @advanceFromStep="advanceFromStep"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GlobalSuppressionImport',

  components: {
    GlobalSuppressionFileUpload: () => import('@/views/pages/suppression/GlobalSuppressionFileUpload'),
    ImportGlobalSuppression: () => import('@/views/pages/suppression/ImportGlobalSuppression'),
    GlobalSuppressionSummary: () => import('@/views/pages/suppression/GlobalSuppressionSummary')
  },
  data () {
    return {
      stepCount: 1,
      routes: [
        {
          path: 'globalSuppressionFileUpload',
          ref: 'globalSuppressionFileUpload',
          stepVal: 1,
          key: 0
        },
        {
          path: 'importGlobalSuppression',
          ref: 'importGlobalSuppression',
          stepVal: 2,
          key: 0
        },
        {
          path: 'globalSuppressionSummary',
          ref: 'globalSuppressionSummary',
          stepVal: 3,
          key: 0
        }
      ],
      valid: false,
      isSingleColumn: false,
      fileData: '',
      row: '',
      row0: '',
      fileArray: [],
      resultsLoading: false,
      uploadingFiles: false,
      uploadError: ''
    }
  },

  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId'
    ]),
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    },
    isLoading () {
      return this.loadingCount > 0
    }
  },
  methods: {
    clearErrors () {
      this.uploadError = ''
    },
    getStepVal (stepRef) {
      return this.routes.find(x => x.ref === stepRef).stepVal
    },
    getStepKey (stepRef) {
      return this.routes.find(x => x.ref === stepRef).key
    },
    async getRow (row0) {
      this.row0 = row0
    },
    async getFileArray (fileArray) {
      this.fileArray = fileArray
    },
    async getFileData (fileData) {
      this.fileData = fileData
    },
    async cancelStep (stepNum) {
      this.stepCount = stepNum - 1
    },
    async advanceFromStep (stepNum) {
      this.stepCount = stepNum + 1
    }
  }
}
</script>
